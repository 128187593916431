// higher order components
import React, { useCallback } from "react";
import each from 'lodash/each';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { parseURL } from '../common/helpers';
import { M_PREFERENCES, RPLY_ERRAND, emptyObject } from '../common/v5/constants';
import { push } from '../common/v5/utils';
import { composeWithDisplayName } from '../reactcomponents/hocs';
import { confirmExternalLink } from '../redux/actions/async/chat';
import { closeResetErrandView, setAgentAssistTogglePref } from '../redux/actions/async/errand';
import {
	expandHideOption,
	setSetRAQuestion,
	updateAIAnswerstate,
	toggleRightSidePanel,
	showErrandRewriteAnswerPopup,
	resetAIAnswer,
	doExpandKnowledgeBasePanel,
	resetErrandLibrarySearch,
	setKBTreeParent
} from '../redux/actions/errand';
import { resetEdit } from '../redux/actions/knowledgebase';
import { fetchKnowledgeBaseCategory , fetchKnowledgeBase } from '../redux/actions/async/knowledgebase';
import { hasCollaborationMemo, hasCRMEmailTriggerMemo } from '../redux/selectors/collaborate';
import {
	agentTimezoneOffsetSelector,
	associatedErrandsMemo,
	linkedErrandsMemo,
	currentErrandDisplayIdMemo,
	isOpenedNormalErrand,
	getCurrentChannel,
	getCurrentErrand,
	rewriteAnsBaseDataSelector,
	knowledgeBaseListSelector,
	getLlmSessionIdSelector
} from '../redux/selectors/errand';
import { totalQueueChats } from '../redux/selectors/chat';
import { servicesByType } from '../redux/selectors/server';
import { popErrorOnly } from '../redux/actions/hmf';

export const connectWithoutDispatch = mapState => connect(mapState, emptyObject)

export const withAnsweredStates = connectWithoutDispatch(
	state => ({
		associatedErrands: associatedErrandsMemo(state),
		servicesByType: servicesByType(state)
	})
)

export const withLinkedErrandsStates = connectWithoutDispatch(
	state => ({
		linkedErrands: linkedErrandsMemo(state),
		servicesByType: servicesByType(state)
	})
)

export const withDisplayIdAndChannelId = connectWithoutDispatch(
	state => ({
		channelId: getCurrentChannel(state),
		displayId: currentErrandDisplayIdMemo(state)
	})
)

const returnEmptyObject = () => emptyObject

export const connectWithoutState = mapDispatch => connect(
	returnEmptyObject,
	mapDispatch
)

function mapState(state, props) {
	return {
		openedErrand: isOpenedNormalErrand(state, props)
		, errandID: getCurrentErrand(state, props)
	};
}

function mapCloseErrandView(dispatch) {
	return {onCloseErrandView: id => dispatch(closeResetErrandView(id))};
}

const defaultOnLinkChange = dispatch => to => dispatch(push(to));

function mapCloseErrandViewAndLink(dispatch, { onLinkChange }) {
	if (typeof onLinkChange !== "function") {
		onLinkChange = defaultOnLinkChange(dispatch);
	}
	return {
		onCloseErrandView: id => dispatch(closeResetErrandView(id))
		, onLinkChange
	};
}

const withCloseErrandView = connect(mapState, mapCloseErrandView);

const withCloseAndLink = connect(mapState, mapCloseErrandViewAndLink);

function mapConfirmExternalLink(dispatch) {
	return {
		onClickExternalLink: href => dispatch(confirmExternalLink(href))
	};
}

function isSameHost(host) {
	return window.location.hostname === host;
}

const safeHostnames = {
	"cloud.cention.com": true
	, "api.cention.com": true
	, "www.cention.com": true
	, "cention.com": true
};

function isSafeLink(href) {
	const u = parseURL(href);
	if (!u) {
		return false;
	}
	const { hostname } = u;
	if (isSameHost(hostname)) {
		return true;
	} else if (safeHostnames[hostname]) {
		return true;
	}
	return false;
}

function findUnsafeExternalLink({ target }) {
	if (target.tagName === "A" && target.href && !isSafeLink(target.href)) {
		if (process.env.NODE_ENV !== 'production') {
			console.log("dbg: found unsafe external link:", {target});
		}
		return target.href;
	}
}

const withExternalLinkClickCapture = Component => ({
	onClickExternalLink
	, ...props
}) => {
	const handleClickCapture = useCallback(
		e => {
			const href = findUnsafeExternalLink(e);
			if (href) {
				e.preventDefault();
				e.stopPropagation();
				onClickExternalLink(href);
			}
		}
		, [onClickExternalLink]
	);
	return <Component {...props} onClickCapture={handleClickCapture} />;
};

export const withConfirmationExternalLink = composeWithDisplayName(
	"withConfirmationExternalLink"
	, connectWithoutState(mapConfirmExternalLink)
	, withExternalLinkClickCapture
);

function beforeClickHandlers(
	props,
	action,
	prevent,
	persist,
	useEvent,
	event,
	...args
) {
	if (prevent) {
		event.preventDefault();
	}
	if (!props.openedErrand) {
		if (useEvent) {
			props[action](event);
		} else {
			props[action](...args);
		}
		return;
	}
	if (persist) {
		event.persist();
	}
	props.onCloseErrandView(props.errandID)
		.then(() => useEvent ? props[action](event) : props[action](...args))
		.catch(e => {
			if (e instanceof Error) {
				console.trace && console.trace("error:", e);
			}
		});
}

const TYPE_CANCEL_LINK = 1
	, TYPE_CANCEL_CLICK = 2
	, TYPE_CANCEL_ANY = 3
	;
function hocCreation(type, captureAction, callbackAction) {
	return function(Component) {
		return class extends React.PureComponent {
			constructor(props) {
				super(props);
				this.handleBeforeClick = this.handleBeforeClick.bind(this);
			}
			common(...args) {
				let action;
				if (callbackAction) {
					action = callbackAction;
				} else {
					action = captureAction;
				}
				beforeClickHandlers(this.props, action, ...args);
			}
			handleBeforeClick(arg, ...args) {
				switch (type) {
					case TYPE_CANCEL_LINK:
						this.common(true, false, false, arg, this.props.to);
						break;
					case TYPE_CANCEL_CLICK:
						this.common(false, true, true, arg);
						break;
					case TYPE_CANCEL_ANY:
						this.common(false, false, false, null, arg, ...args);
						break;
				}
			}
			render() {
				let {
					onCloseErrandView
					, openedErrand
					, errandID
					, ...props
				} = this.props;
				props[captureAction] = this.handleBeforeClick;
				if (callbackAction) {
					delete props[callbackAction];
				}
				return <Component {...props} />;
			}
		}
	}
}

const cancellableLink = hocCreation(TYPE_CANCEL_LINK, 'onClick', 'onLinkChange');

const cancellableClick = hocCreation(TYPE_CANCEL_CLICK, 'onClick');

function createCancellableActionHOC(action) {
	return hocCreation(TYPE_CANCEL_ANY, action);
}

export const withCancellableLink = compose(withCloseAndLink, cancellableLink);

export const withCancellableClick = compose(withCloseErrandView, cancellableClick);

export const withCancellableAction = action => compose(withCloseErrandView, createCancellableActionHOC(action));

export const withCancellableOpenErrand = withCancellableAction('openErrand');

export function withPrintNode(Component) {
	return class extends React.PureComponent {
		constructor(props) {
			super(props);
			this.handleClosed = this.handleClosed.bind(this);
			this.handleContentReady = this.handleContentReady.bind(this);
			this.handlePrintAction = this.handlePrintAction.bind(this);
		}
		removePrintNode () {
			if (this.previousNode) {
				document.body.removeChild(this.previousNode);
				this.previousNode = null;
			}
		}
		componentWillUnmount () {
			this.removePrintNode();
			if (this.delayTimer) {
				window.clearTimeout(this.delayTimer);
				this.delayTimer = null;
			}
		}
		createNode (content) {
			const node = document.createElement("div");
			node.innerHTML = content;
			if (this.previousNode) {
				document.body.removeChild(this.previousNode);
			}
			this.previousNode = node;
			document.body.appendChild(node);
		}
		handleClosed (...args) {
			this.removePrintNode();
			if (typeof this.props.onClosed === "function") {
				this.props.onClosed(...args);
			}
		}
		showWindowPrint (content) {
			const { delay } = this.props;
			this.createNode(content);
			if (typeof delay !== 'number' || delay < 0) {
				return;
			} else if (delay > 0) {
				if (this.delayTimer) {
					window.clearTimeout(this.delayTimer);
				}
				this.delayTimer = window.setTimeout(() => {
					window.print();
				}, delay);
			} else {
				window.print();
			}
		}
		handleContentReady (id, content, ...args) {
			this.showWindowPrint(content);
			if (typeof this.props.onContentReady === "function") {
				this.props.onContentReady(id, content, ...args);
			}
		}
		handlePrintAction (id, content, ...args) {
			// this allow content always latest though it unlikely change.
			this.createNode(content);
			if (this.previousNode) {
				window.print();
			}
			if (typeof this.props.onPrintAction === "function") {
				this.props.onPrintAction(id, content, ...args);
			}
		}
		render () {
			const {
				onClosed
				, onContentReady
				, onPrintAction
				, delay
				, ...props
			} = this.props;
			return (
				<Component {...props}
					onContentReady={this.handleContentReady}
					onPrintAction={this.handlePrintAction}
					onClosed={this.handleClosed}
				/>
			);
		}
	}
}

function mapQueueChatState(state, props) {
	return {
		data: totalQueueChats(state)
	};
}

export const withTotalQueueChats = connect(mapQueueChatState);

const mapMoment = (state, props) => ({
	timezoneOffset: agentTimezoneOffsetSelector(state, props)
});

export function withAgentTimezonOffset(Component) {
	return connect(mapMoment)(Component);
}

const mapErrandMessages = (state, props) => {
	const { ui } = state.app.errand;
	const wf = state.app.workflow, wfs = wf.fetchWfSettings.data;
	return {
		showIC: ui.showComment,
		showCollab: ui.collaboration.show,
		hasCollab: hasCollaborationMemo(state),
		showCRM: ui.showCRM,
		hasCRM: ui.hasCRM,
		rightSidePanelOpen: ui.rightSidePanelOpen,
		showAIAnswerPanel: ui.showAIAnswerPanel,
		pinnedShowAIAnswerPanel: wfs.showAgentAssistPanel,
		showKnowledgeBasePanel: ui.showKnowledgeBasePanel,
		currentQues: ui.rewriteAnswerBase.currentQues,
		newAIContext: ui.rewriteAnswerBase.newContext,
		AISession: getLlmSessionIdSelector(state, props),
		generatingAns: ui.rewriteAnswerBase.generatingAns,
		dataSrc: rewriteAnsBaseDataSelector(state, props),
		kbList: knowledgeBaseListSelector(state, props),
		expandKnowledgeBasePanel: ui.expandKnowledgeBasePanel,
		libraryAnswerShown: ui.knowledgeBase.shownId ? true : false,
		selectedKBLibrary: ui.knowledgeBase.selectedLibrary,
		selectedKBCategory: ui.knowledgeBase.selectedCategory,
	}
}

const mapErrandMessagesCallback = dispatch => {
	return {
		onToggleShow: (type, eid, toggleState) => {
			dispatch(expandHideOption(type, eid, toggleState));
		},
		onToggleRightsidepanel: (toggle) => {
			dispatch(toggleRightSidePanel(toggle));
		},
		onMaximizeAIPanel: () => {
			dispatch(showErrandRewriteAnswerPopup(true, RPLY_ERRAND));
		},
		onResetAIAnswer: () => {
			dispatch(resetAIAnswer('', false, false));
		},
		onBackToMainKBList: (library, category) => {
			if(category && category !== 0) {
				dispatch(fetchKnowledgeBaseCategory(category));
				dispatch(setKBTreeParent(""));
			} else {
				dispatch(fetchKnowledgeBase(library));
				if(category === 0) {
					dispatch(setKBTreeParent(""));
				} else {
					dispatch(setKBTreeParent(0)); //to indicate that we are at the root level
				}
			}
			dispatch(resetErrandLibrarySearch());
		},
		onExpandKBLibrary: (toggle) => {
			dispatch(doExpandKnowledgeBasePanel(toggle));
			if(toggle) {
				dispatch(expandHideOption('KnowledgeBasePanel', 0, false));
				dispatch(toggleRightSidePanel(false));
			}
		},
		onCloseKBLibraryPanel: (eid) => {
			dispatch(toggleRightSidePanel(false));
			dispatch(expandHideOption('KnowledgeBasePanel', eid, false));
			dispatch(resetErrandLibrarySearch());
			dispatch(resetEdit());
		},
		onPinAgentAssistPanel: (toggle) => {
			dispatch(setAgentAssistTogglePref(toggle));
		}
	};
};
export const withErrandMessages = connect(mapErrandMessages, mapErrandMessagesCallback);

const dispatchMapCreator = {
	onActivate: (title, dispatch, handler) => (which, id, active) => {
		if (process.env.NODE_ENV !== 'production') {
			console.log(`dbg: activate ${title}:`, which, id, active)
		}
		dispatch(handler(which, id, active))
	},
	onDelete: (title, dispatch, handler) => (which, id) => {
		if (process.env.NODE_ENV !== 'production') {
			console.log(`dbg: delete ${title}:`, which, id)
		}
		return dispatch(handler(which, id))
	},
	onEdit: (title, dispatch, handler) => (which, field, operand, value) => {
		if (process.env.NODE_ENV !== 'production') {
			console.log(`dbg: editing ${title} form:`, which, field, operand, value)
		}
		return dispatch(handler(field, operand, value))
	},
	onEditStart: (title, dispatch, handler) => info => {
		if (process.env.NODE_ENV !== 'production') {
			console.log(`dbg: start edit ${title} form:`, info)
		}
		return dispatch(handler(info))
	},
	onFinishEdit: (title, dispatch, handler) => () => {
		if (process.env.NODE_ENV !== 'production') {
			console.log(`dbg: finish edit ${title} form`)
		}
		return dispatch(handler())
	},
	onLoad: (_, dispatch, handler) => (...args) => dispatch(handler(...args)),
	onSave: (title, dispatch, handler) => (info, changes, data) => {
		if (process.env.NODE_ENV !== 'production') {
			console.log(`dbg: ${title} save:`, { info, changes, data })
		}
		return dispatch(handler(info, changes, data))
	}
}

// title: string
// handlers: object
// {
//   onActivate: function,
//   onDelete: function,
//   onEdit: function,
//   onEditStart: function,
//   onFinishEdit: function,
//   onLoad: function,
//   onSave: function
// }
export const createDispatchers = (title, handlers) => dispatch => {
	const dispatchers = {}
	each(handlers, (handler, name) => {
		const f = dispatchMapCreator[name]
		if (typeof f === 'function') {
			dispatchers[name] = f(title, dispatch, handler)
		} else if (typeof handler === 'function') {
			dispatchers[name] = (...args) => dispatch(handler(...args))
		}
	})
	if (typeof dispatchers.onPopAlert === 'undefined') {
		dispatchers.onPopAlert = err => dispatch(popErrorOnly(err))
	}
	return dispatchers
}
